const modifyInternalLinkUrl = (url, isCrossWebsiteLink) => {
  const globalPathPrefix = process.env.GATSBY_GLOBAL_PATH_PREFIX
  const globalPathPrefixDelimiter =
    process.env.GATSBY_GLOBAL_PATH_PREFIX_DELIMITER
  let result = url

  if (url[0] === "#") {
    return url
  }

  if (url[0] === "/") {
    result = url.substring(1)
  }
  // detect double leading slashes
  if (result[0] === "/") {
    result = result.substring(1)
  }

  if (!isCrossWebsiteLink) {
    if (
      globalPathPrefix &&
      globalPathPrefixDelimiter &&
      result.indexOf(`${globalPathPrefix}${globalPathPrefixDelimiter}`) !== 0
    ) {
      result = `${globalPathPrefix}${globalPathPrefixDelimiter}${result}`
    }
  }

  return `/${result.toLowerCase()}`
}

export { modifyInternalLinkUrl }
